import { getUsers, createUsers, enableForum, disableForum } from './services'
import { getExistingIacSession, previousTripletMatches } from '../../services/session'
import { findIndividualAccount } from '@/utils/user'

export const defaultUserSettings = () => ({
  hasValidSession: false,
  timerActive: false,
  activeUsers: []
})

const FETCH_SESSION_INTERVAL = 5 * 60 * 1000 // 5 minutes

let timeoutId = null

const userSettings = {
  namespaced: true,
  state: defaultUserSettings(),
  mutations: {
    SET_SESSION(state, status) {
      state.hasValidSession = status
    },
    RESET_STATE(state) {
      Object.assign(state, defaultUserSettings())
      clearTimeout(timeoutId)
    },
    SET_TIMER_STATE(state, status) {
      state.timerActive = status
    },
    SET_USERS(state, users) {
      state.activeUsers = users
    }
  },
  actions: {
    async refreshSession(context) {
      const response = {
        data: {
          status: true
        }
      }

      const uuidCookie = context.rootState.user.uuid
      const previousTriplet = context.rootState.user.sessionTriplet
      const session = await getExistingIacSession(uuidCookie)

      const individualUser = findIndividualAccount(session.authAccts)

      // If the session returns different access triplet than what's stored, refresh cookies
      if (!previousTripletMatches(previousTriplet, session.sessionTriplet)) {
        // set new cookies in browser!
        context.dispatch('user/setSessionTriplet', session.sessionTriplet, { root: true })
      }

      if (individualUser) {
        context.commit('SET_SESSION', true)
        context.commit('SET_TIMER_STATE', true)
        context.dispatch('scheduleRefreshSession')
      } else {
        // log user out and reset state
        response.data.status = false
      }

      return response
    },
    scheduleRefreshSession(context) {
      if (!context.state.timerActive) {
        return
      }

      timeoutId = setTimeout(() => context.dispatch('refreshSessionInterval'), FETCH_SESSION_INTERVAL)
      return timeoutId
    },
    async refreshSessionInterval(context) {
      let session = false
      const maxTries = 2
      for (let index = 1; index <= maxTries; index++) {
        if (!context.state.timerActive) {
          return
        }

        const res = await context.dispatch('refreshSession')
        const user = res.data
        if (user.status) {
          session = true
          break
        } else if (index < maxTries) {
          await new Promise(resolve => setTimeout(resolve, 1000))
        }
      }

      if (!session) {
        context.commit('RESET_STATE')
      } else {
        context.dispatch('scheduleRefreshSession')
      }
    },
    async fetchUsers(context) {
      try {
        const res = await getUsers()
        context.commit('SET_USERS', res.data)
        return res
      } catch (err) {
        if (err.response.status === 401) {
          context.commit('RESET_STATE')
        }

        throw err
      }
    },
    async createUsers(context, options) {
      try {
        const { emails } = options
        const res = await createUsers(emails)
        return res
      } catch (err) {
        if (err.response.status === 401) {
          context.commit('RESET_STATE')
        }

        throw err
      }
    },
    async enableForum(context, options) {
      try {
        const { profileId, isAdmin } = options
        const res = await enableForum(profileId, isAdmin)
        return res
      } catch (err) {
        if (err.response.status === 401) {
          context.commit('RESET_STATE')
        }

        throw err
      }
    },
    async disableForum(context, options) {
      try {
        const { profileId } = options
        const res = await disableForum(profileId)
        return res
      } catch (err) {
        if (err.response.status === 401) {
          context.commit('RESET_STATE')
        }

        throw err
      }
    }
  },
  getters: {
    forumUsers: state =>
      state.activeUsers
        .filter(user => user.ssenabled === true)
        .map(user => {
          user['ssAdmin'] = user.roles.split(',').includes('SHARED_SHELF_ADMIN')
          return user
        })
  }
}

export default userSettings
