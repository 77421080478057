import { v2SessionClient, federatedGraphqlClient } from '../vue-apollo'
import StartIacSession from '../graphql/federated-gql/StartIacSession.gql'
import GetIacSession from '../graphql/federated-gql/GetIacSession.gql'
import SessionFragment from '../graphql/federated-gql/IacSessionFragment.gql'
import GenerateForumSSCookie from '../graphql/federated-gql/GenerateSSCookie.gql'
import UserLogOutForum from '../graphql/federated-gql/LogoutUser.gql'
import UserLoginForum from '../graphql/federated-gql/LoginUser.gql'
import gql from 'graphql-tag'

export const startIacSession = async () => {
  const response = await v2SessionClient.query({
    query: gql`
      ${StartIacSession}
      ${SessionFragment}
    `,
    fetchPolicy: 'no-cache'
  })
  return response.data.session
}

export const getExistingIacSession = async uuid => {
  if (!uuid) {
    throw new Error('No UUID provided')
  }
  const response = await v2SessionClient.query({
    query: gql`
      ${GetIacSession}
      ${SessionFragment}
    `,
    variables: { uuid },
    fetchPolicy: 'no-cache'
  })
  return response.data.session
}

export const generateSharedShelfCookie = async () => {
  const response = await federatedGraphqlClient.query({
    query: GenerateForumSSCookie,
    fetchPolicy: 'no-cache'
  })

  return response.data.generateSharedShelfCookie.cookie
}

export const previousTripletMatches = (previousTriplet, sessionTriplet) => {
  return (
    previousTriplet.accessSession === sessionTriplet.accessSession &&
    previousTriplet.accessSessionSignature === sessionTriplet.accessSessionSignature &&
    previousTriplet.accessSessionTimedSignature === sessionTriplet.accessSessionTimedSignature
  )
}

export const logUserIn = async (email, password) => {
  if (!email || !password) {
    throw new Error('No email or password provided')
  }
  const response = await federatedGraphqlClient.mutate({
    mutation: UserLoginForum,
    variables: {
      loginInput: {
        login: email,
        password: password,
        keepMeLoggedIn: false
      }
    },
    fetchPolicy: 'no-cache'
  })

  return response.data.userLogin
}

export const logUserOut = async () => {
  const response = await federatedGraphqlClient.mutate({
    mutation: UserLogOutForum,
    fetchPolicy: 'no-cache'
  })

  return response.data.userLogOut
}
