export const TARGET_TYPE_ENUM = {
  INSTITUTIONAL: 100,
  PERSONAL: 125,
  SAHARA: 150,
  PUBLIC: 200,
  OMEKA_API: 251,
  OMEKA_PLUGIN: 250,
  OAI: 300,
  IDP: 800, //formerly labeled as DUBLIN_CORE
  DARWIN_CORE: 801,
  SAOA: 802,
  JSTOR: 900
}

export const ARTSTOR_TARGET_TYPES = [
  TARGET_TYPE_ENUM.INSTITUTIONAL,
  TARGET_TYPE_ENUM.PUBLIC,
  TARGET_TYPE_ENUM.PERSONAL,
  TARGET_TYPE_ENUM.SAHARA
]

export const ARTSTOR_HEADERS = [
  'Institutional Collection in Artstor',
  'Public Collection in Artstor',
  'Personal Collection in Artstor'
]

export const JSTOR_TARGET_TYPES = [TARGET_TYPE_ENUM.JSTOR]

export const OMEKA_TARGET_TYPES = [TARGET_TYPE_ENUM.OMEKA_API, TARGET_TYPE_ENUM.OMEKA_PLUGIN]

export function overwriteTargetTypeDescription(targets) {
  const public_desc = `JSTOR is a platform through which subscribers can view content from various sources. JSTOR does not screen or select the content you choose to publish to Institutional Collections or clear any rights with respect to them.`
  const institutional_desc = `Institutional Collections consist of content uploaded or made available on the JSTOR Platform by a JSTOR Forum Authorized User via JSTOR Forum. <br/><br/>Institutional Collections appear on the JSTOR platform listed as a Collection using the publishing Institution’s name, and may be viewed or shared alongside other content on the JSTOR Platform.<br/><br/>${public_desc}`

  targets.forEach(target => {
    if (target.target_type === TARGET_TYPE_ENUM.JSTOR) {
      if (target.target_configuration && JSON.parse(target.target_configuration).type === 'INSTITUTION') {
        target.target_name = 'Institutional Collection in JSTOR'
        target.target_description = institutional_desc
      } else {
        target.target_name = 'Public Collection in JSTOR'
        target.target_description = public_desc
        target.target_access = 'Open Access'
      }
    }
    return target
  })

  return targets
}

export function isArtstorHeader(header) {
  return ARTSTOR_HEADERS.some(artstorHeader => header.includes(artstorHeader))
}

export function isArtstorTarget(target) {
  return ARTSTOR_TARGET_TYPES.includes(target.target_type)
}

export function isJstorTarget(target) {
  return target.target_type === TARGET_TYPE_ENUM.JSTOR
}

export function isOAITarget(target) {
  return target.target_type === TARGET_TYPE_ENUM.OAI
}
