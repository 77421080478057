import axios from 'axios'
import { isProd } from '@/common/environment'

/**
 * @description get a given asset
 * @param {string} assetId asset id
 * @returns {Promise} axios request promise
 */
export function getAsset(assetId) {
  if (!assetId) {
    console.warn('Get asset received no asset id')
    return
  }

  return axios
    .get(`/assets/${assetId}`)
    .then(resp => resp)
    .catch(() => {
      console.warn(`Unable to get asset ${assetId}`)
      return
    })
}

/**
 * @description save asset drs data
 * @param {object} assetData
 * @returns {Promise} axios request promise
 */
export function saveAssetDrsData(assetData) {
  const formData = new FormData()
  formData.set('data', JSON.stringify(assetData))

  return axios
    .post('/assets/update-assets-drs-data', formData)
    .then(resp => resp)
    .catch(() => {
      console.warn(`Unable to update asset drs`)
      return
    })
}

/**
 * @description get publication status for a given asset
 * @param {number} id asset id
 * @returns {Promise} axios request promise
 */
export function getAssetPublicationStatus(id) {
  if (!id) {
    console.warn('Get publication status received no asset id')
    return
  }

  return axios
    .get(`/assets/${id}/publication-status.json`)
    .then(resp => resp)
    .catch(() => {
      console.warn(`Unable to get publication status for asset ${id}`)
      return
    })
}

/**
 * @description get a short url for a given asset
 * @param {string} id asset id
 * @param {string} representationId image id
 * @returns {Promise} axios request promise
 */
export function getAssetShortURL(id, representationId) {
  if (!id) {
    console.warn('Get asset short url received no asset id')
    return
  }

  const dc = representationId ? representationId.toString(16) : null // convert to hex string

  return axios
    .get(`/assets/${id}/shorten?_dc=${dc}`)
    .then(resp => resp)
    .catch(() => {
      console.warn(`Unable to get short url for asset ${id}`)
      return
    })
}

/**
 * @description get all media objects for a given asset
 * @param {string} assetId asset id
 * @returns {Promise} axios request promise
 */
export async function getAssetRepresentationDetails(assetId, representationId) {
  if (!assetId) {
    console.warn('Get representation details received no asset id')
    return
  }

  const dc = representationId ? representationId.toString(16) : null // convert to hex string

  return await axios.get(`/assets/${assetId}/representation/details?_dc=${dc}`).catch(() => {
    console.warn(`Unable to get representation details for asset ${assetId}`)
  })
}

/**
 * @description get the upload status of asset
 * @param {string} storId stor id
 * @returns {Promise} axios request promise
 */
export async function getAssetRepresentationStatus(storId) {
  if (!storId) {
    console.warn('Get representation details received no stor id')
    return
  }

  return await axios
    .get(`https://${isProd() ? 'stor.artstor.org' : 'stor.stage.artstor.org'}/stor/v2/uploads/${storId}/status`, {
      withCredentials: false
    })
    .catch(() => {
      console.warn(`Unable to get representation details for stor id ${storId}`)
    })
}

/**
 * @description get all media objects for a given asset
 * @param {string} assetId asset id
 * @returns {Promise} axios request promise
 */
export function getAssetMediaObjects(assetId) {
  if (!assetId) {
    console.warn('Get media objects received no asset id')
    return
  }

  return axios
    .get(`/assets/${assetId}/media-objects`)
    .then(resp => resp)
    .catch(() => {
      console.warn(`Unable to get media objects for asset id ${assetId}`)
      return
    })
}

/**
 * @description delete a given assets media object
 * @param {string} assetId asset id
 * @param {string} objectId media object id
 * @returns {Promise} axios request promise
 */
export function deleteAssetMediaObject(assetId, objectId) {
  if (!assetId) {
    console.warn('Delete media object received no asset id')
    return
  }

  return axios
    .delete(`/assets/${assetId}/media-objects/${objectId}`)
    .then(resp => resp)
    .catch(() => {
      console.warn(`Unable to delete asset ${assetId} object ${objectId}`)
      return
    })
}

/**
 * @description get doi for a given asset id
 * @param {string} assetId asset id
 * @returns {Promise} axios request promise
 */
export function getAssetDoi(assetId) {
  if (!assetId) {
    console.warn('Get asset doi received no asset id')
    return
  }

  return axios
    .get(`/id-service/ssid/${assetId}`)
    .then(resp => resp)
    .catch(() => {
      console.warn(`Unable to get asset doi for asset id ${assetId}`)
      return
    })
}
