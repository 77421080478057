export const findIndividualAccount = iacAuthAccounts => {
  if (!iacAuthAccounts || !Array.isArray(iacAuthAccounts)) {
    return
  }

  return iacAuthAccounts.find(account => account.type.toLowerCase() === 'individual')
}

export const checkForumPermissions = sessionProfiles => {
  if (!sessionProfiles || !Array.isArray(sessionProfiles)) {
    return false
  }

  let foundAccess = false

  for (let profile of sessionProfiles) {
    if (profile.role.includes('SHARED_SHELF_USER') || profile.role.includes('SHARED_SHELF_ADMIN')) {
      foundAccess = true
      break
    }
  }

  return foundAccess
}
