import axios from 'axios'
import { md5 } from 'js-md5'
import { supportedVideoExtensions, supportedAudioExtensions } from '@/common/uploads'
import CryptoJS from 'crypto-js'

const isProd = document.domain === 'forum.jstor.org'
const storUrl = isProd ? 'stor.artstor.org' : 'stor.stage.artstor.org'
const CHUNK_SIZE = 10000000
const MULTIPART_THRESHOLD = 1024 * 1024 * 400

/**
 * @description get IIIF variants
 * @param uuid id from uploadImage res
 * @returns {Promise} axios request promise
 */
export async function getStorDetails(uuid) {
  return await axios.get(`https://${storUrl}/stor/${uuid}.json`, { withCredentials: false })
}

/**
 * @description returns the preferred media source for handling uploads
 * @param {number} projectId id of the project
 * @returns {Promise} axios request promise
 */
export function getProjectMediaSourceForUpload(projectId) {
  return axios.get(`/projects/${projectId}/media-source-for-upload`)
}

/**
 * @description reserve a media before uploading
 * @param {number} projectId id of the project
 * @param {string} name file name
 * @param {string} size file size
 * @returns {Promise} axios request promise
 */
export function saveProjectsMediaSourceReserve(projectId, name, size, cancelToken) {
  const options = {
    cancelToken
  }

  const reserveFormData = new FormData()
  reserveFormData.append('name', name)
  reserveFormData.append('size', size)

  return axios.post(`/projects/${projectId}/media-sources/reserve`, reserveFormData, options).then(res => res.data)
}

/**
 * @description uploadToken stores all the tokens.
 */
let uploadTokens = []

/**
 * @description get a list of valid tokens for uploads
 * @returns {Promise} axios request promise
 */
export async function getTokens() {
  return axios.get('/get_tokens').then(res => res.data.tokens)
}

/**
 * @description get a token for uploading a file
 * @returns {Promise} axios request promise
 */
export async function getToken() {
  if (uploadTokens.length === 0) {
    uploadTokens = await getTokens()
  }
  return uploadTokens.pop()
}

/**
 * upload a single file through ADAM (legacy upload flow)
 * @param {number} projectId
 * @param {File} file to upload
 * @param {function} onProgress callback for onProgress
 * @param {AxiosCancelToken} cancelToken
 */
export async function uploadFileThroughAdam(projectId, file, onProgress, cancelToken, ocr) {
  // 1) Get Upload Token
  const token = await getToken()

  // 2) Get Reserve Info
  const reserveData = await saveProjectsMediaSourceReserve(projectId, file.name, file.size, cancelToken)

  // 3) POST to STOR
  const formData = { ...reserveData, File: file, token: token, ...(ocr && { ocr }) }
  return saveToUrl(reserveData.location, formData, onProgress, cancelToken)
}

/**
 * upload a single file directly (for supported projects only)
 * @param {string} storUrl of the upload server
 * @param {File} file to upload
 * @param {function} onProgress callback for onProgress
 * @param {AxiosCancelToken} cancelToken
 */
export async function uploadFile(storUrl, file, onProgress, cancelToken, ocr) {
  try {
    const options = {
      withCredentials: false,
      cancelToken: cancelToken
    }
    return await triageUpload(storUrl, file, options, ocr, onProgress, true)
  } catch (error) {
    if (error.toString() === 'Cancel') {
      throw error
    }
  }
}

export async function uploadSingleFile(file, cancelToken) {
  const options = {
    withCredentials: false,
    cancelToken: cancelToken
  }

  return await triageUpload(storUrl, file, options)
}

async function triageUpload(storUrl, file, options, ocr, onProgress, useAsync) {
  try {
    if (useAsync || useAsyncWorkflow(file)) {
      return file.size <= MULTIPART_THRESHOLD
        ? await sendItemToStor(storUrl, file, options, onProgress)
        : await sendMultipartItemToStor(storUrl, file, options, onProgress)
    } else {
      const token = await getToken()
      const formData = { file, token, ...(ocr && { ocr }) }
      return saveToUrl('https://' + storUrl + '/stor', formData, onProgress, options.cancelToken)
    }
  } catch (error) {
    if (error.toString() === 'Cancel') {
      throw error
    }
  }
}

function useAsyncWorkflow(file) {
  const fileExtension = `.${file.name.split('.').pop()}`
  return supportedAudioExtensions.includes(fileExtension) || supportedVideoExtensions.includes(fileExtension)
}

/**
 * uploading a file with progress
 * @param {string} url
 * @param {Object} params to post as form data
 * @param {function} onProgress callback for onProgress
 * @param {AxiosCancelToken} cancelToken
 */
export function saveToUrl(url, params, onProgress, cancelToken) {
  const formData = new FormData()

  Object.keys(params).forEach(key => {
    formData.append(key, params[key])
  })
  const options = {
    withCredentials: false,
    cancelToken: cancelToken,
    onUploadProgress(progressEvent) {
      const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total
      if (onProgress) {
        onProgress(percentCompleted)
      }
    }
  }

  const res = axios.post(url, formData, options)
  return res
}

export const sendItemToStor = async (storUrl, itemToUpload, options, onProgress) => {
  const binaryConversion = await convertToBinary(itemToUpload)
  const uniqueIdentifier = await generateMd5(itemToUpload)

  const presignedPayload = new FormData()
  presignedPayload.append('ocr', 'true')
  presignedPayload.append('file.name', itemToUpload.name.toLowerCase())
  presignedPayload.append('file.md5', uniqueIdentifier || '')
  presignedPayload.append('file.size', itemToUpload.size)
  presignedPayload.append('mimetype', itemToUpload.type)

  const progressCallback = progressEvent => {
    const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total
    if (onProgress) {
      onProgress(percentCompleted)
    }
  }

  try {
    let config = { ...options, headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: {} }
    const storResponse = await axios.post(`https://${storUrl}/stor/v2`, presignedPayload, config)
    const { presigned_s3_url, id, pyrimidal_image_path } = storResponse?.data || {}

    config = { ...options, headers: { 'Content-Type': itemToUpload.type }, onUploadProgress: progressCallback }
    await axios.put(presigned_s3_url, binaryConversion, config)

    return {
      success: true,
      data: {
        id: id,
        pyrimidal_image_path: pyrimidal_image_path
      }
    }
  } catch (error) {
    console.log(`Error fetching presigned url from stor: ${error}`)
    if (error.toString() === 'Cancel') {
      throw error
    }
    return { success: false, error: 'There was an error saving your media, please try again' }
  }
}

export const sendMultipartItemToStor = async (storUrl, itemToUpload, options, onProgress) => {
  const uniqueIdentifier = await generateMd5Multipart(itemToUpload, onProgress)

  const presignedPayload = new FormData()
  presignedPayload.append('file.name', itemToUpload.name.toLowerCase())
  presignedPayload.append('file.md5', uniqueIdentifier || '')
  presignedPayload.append('file.size', itemToUpload.size)
  presignedPayload.append('mimetype', itemToUpload.type)

  try {
    const sessionConfig = { ...options, headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: {} }
    const storResponse = await axios.post(`https://${storUrl}/stor/v2/multipart`, presignedPayload, sessionConfig)
    const { id: uploadSession, part_urls: partUrls, pyrimidal_image_path: pyramidalImagePath } =
      storResponse?.data || {}

    const parts = await uploadFileChunks(partUrls, itemToUpload, options, onProgress)

    await axios.post(
      `https://${storUrl}/stor/v2/multipart/${uploadSession}/complete`,
      { parts, upload_uuid: uploadSession },
      { ...sessionConfig, headers: { 'Content-Type': 'application/json' } }
    )

    return {
      success: true,
      data: {
        id: uploadSession,
        pyrimidal_image_path: pyramidalImagePath
      }
    }
  } catch (error) {
    console.log(`Error uploading to stor: ${error}`)
    if (error.toString() === 'Cancel') {
      throw error
    }
    return { success: false, error: 'There was an error saving your media, please try again' }
  }
}

const uploadFileChunks = async (partUrls, itemToUpload, options, onProgress) => {
  const numParts = partUrls.length
  let fileProgress = {}

  const responses = await Promise.all(
    partUrls.map(async (presignedPartUrl, index) => {
      const sliceStart = index * CHUNK_SIZE
      const sliceEnd = sliceStart + CHUNK_SIZE
      const fileBlob = index < numParts ? itemToUpload.slice(sliceStart, sliceEnd) : itemToUpload.slice(sliceStart)

      const progressCallback = progressEvent => {
        fileProgress[index] = (progressEvent.loaded * 100) / progressEvent.total
        let totalPercent = fileProgress
          ? Object.values(fileProgress).reduce((accumulator, percentage) => accumulator + percentage, 0)
          : 0
        const percentCompleted = Math.ceil(totalPercent / numParts)
        if (onProgress) {
          onProgress(percentCompleted)
        }
      }
      const putResponse = await axios.put(presignedPartUrl, fileBlob, {
        ...options,
        headers: { 'Content-Type': '' },
        onUploadProgress: progressCallback
      })
      return { ETag: putResponse.headers.etag, PartNumber: index + 1 }
    })
  )

  return responses
}

export const generateMd5 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const fdata = reader.result
      const fileData = new Uint8Array(fdata)
      const hash = md5.create()
      hash.update(fileData)
      hash.hex()
      resolve(hash)
    }
    reader.onerror = () => {
      reject(new Error('Failed to read file'))
    }

    reader.readAsArrayBuffer(file)
  })
}

function readChunked(file, chunkCallback, endCallback) {
  var fileSize = file.size
  var offset = 0

  var reader = new FileReader()
  reader.onload = function() {
    if (reader.error) {
      endCallback(reader.error || {})
      return
    }
    offset += reader.result.length
    chunkCallback(reader.result, offset, fileSize)
    if (offset >= fileSize) {
      endCallback(null)
      return
    }
    readNext()
  }

  reader.onerror = function(err) {
    endCallback(err || {})
  }

  function readNext() {
    var fileSlice = file.slice(offset, offset + CHUNK_SIZE)
    reader.readAsBinaryString(fileSlice)
  }
  readNext()
}

function generateMd5Multipart(blob, onProgress) {
  return new Promise((resolve, reject) => {
    var md5 = CryptoJS.algo.MD5.create()
    readChunked(
      blob,
      (chunk, offset, fileSize) => {
        onProgress((offset * 100) / fileSize)
        md5.update(CryptoJS.enc.Latin1.parse(chunk))
      },
      err => {
        if (err) {
          reject(err)
        } else {
          var hash = md5.finalize()
          var hashHex = hash.toString(CryptoJS.enc.Hex)
          resolve(hashHex)
        }
      }
    )
  })
}

export const convertToBinary = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async () => {
      const fileData = new Uint8Array(reader.result)
      resolve(fileData)
    }
    reader.onerror = () => {
      reject(new Error('Failed to read file'))
    }
    reader.readAsArrayBuffer(file)
  })
}
