export const supportedImgExtensions = [
  '.tif',
  '.jpg',
  '.png',
  '.tiff',
  '.jpeg',
  '.jp2',
  '.jpx',
  '.j2k',
  '.jpf',
  '.j2c',
  '.jpc',
  '.crw',
  '.cr2',
  '.dng',
  '.zvi',
  '.gif',
  '.ai',
  '.eps',
  '.psd',
  '.svg',
  '.bmp',
  '.nef'
]
export const supportedAudioExtensions = ['.wav', '.mp3']
export const supportedVideoExtensions = ['.asf', '.qt', '.mov', '.mpg', '.mpeg', '.avi', '.wmv', '.mp4', '.m4v', '.3gp']

export const legacyArtstorExtensions = [
  '.pptx',
  '.docx',
  '.pdf',
  '.xlsx',
  '.flv',
  '.f4v',
  '.m1v',
  '.m2v',
  '.mkv',
  '.ogg',
  '.rm',
  '.webm',
  '.doc',
  '.ppt',
  '.xls',
  '.m4a'
]

// JSTOR production supported formats
export const supportedJstorExtensions = [
  ...supportedImgExtensions,
  ...supportedAudioExtensions,
  ...supportedVideoExtensions,
  '.pdf'
]
// Artstor production supported formats
export const supportedArtstorExtensions = [...supportedImgExtensions, ...legacyArtstorExtensions]

// Extension lists during AJI transitions
export const supportedArtstorAjiExtensions = [...supportedImgExtensions, ...legacyArtstorExtensions]

// All file types
export const supportedExtensions = [
  ...supportedImgExtensions,
  ...supportedAudioExtensions,
  ...supportedVideoExtensions,
  ...legacyArtstorExtensions
]
