import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

export function getUUIDCookie() {
  const uuid = Cookies.get('UUID')

  return uuid || ''
}

export function setUUIDCookie() {
  const isLocal = window.location.host === 'localhost'
  const userUUID = uuidv4()
  Cookies.set('UUID', userUUID, { secure: !isLocal, expires: 3 * 365 })

  return userUUID
}

export function setSessionTripletCookies(sessionTriplet) {
  const accessSessionCookie = sessionTriplet.accessSession
  const accessSessionSignatureCookie = sessionTriplet.accessSessionSignature
  const accessSessionTimedSignatureCookie = sessionTriplet.accessSessionTimedSignature

  Cookies.set('AccessSession', accessSessionCookie)
  Cookies.set('AccessSessionSignature', accessSessionSignatureCookie)
  Cookies.set('AccessSessionTimedSignature', accessSessionTimedSignatureCookie)

  return sessionTriplet
}

export function getForumCookie() {
  const sharedShelfCookie = Cookies.get('sharedshelf')

  return sharedShelfCookie || ''
}

export function setForumCookie(token) {
  const isLocal = window.location.host === 'localhost'
  Cookies.set('sharedshelf', token, { secure: !isLocal, expires: 3 * 365, path: '/' })

  return token
}

export function deleteForumCookie() {
  Cookies.remove('sharedshelf', { path: '/' })

  return
}
