import axios from 'axios'
import { removeNullUndefined } from '../utils/objectUtils'

const CancelToken = axios.CancelToken

/**
 * @description fetch a list of controlled lists
 * @returns {Promise} axios request promise
 */
export function getControlledLists(cancelToken = {}) {
  if (cancelToken && cancelToken.cancel) {
    cancelToken.cancel()
  }
  Object.assign(cancelToken, CancelToken.source())
  return axios.get('/vocab-projects', { cancelToken: cancelToken.token })
}

/**
 * @description query linked terms for a given field
 * @param {string} id lookup field id
 * @param {object} params query params
 * @returns {Promise} axios request promise
 */
export function queryLinkedTerms(id, params, cancelToken = {}) {
  if (cancelToken && cancelToken.cancel) {
    cancelToken.cancel()
  }
  Object.assign(cancelToken, CancelToken.source())
  const queryParams = new URLSearchParams(removeNullUndefined(params)).toString()

  return axios.get(`/mfcl/${id}/search/terms?${queryParams}`, { cancelToken: cancelToken.token })
}

/**
 * @description delete a given controlled list term
 * @param {string} assetId asset id
 * @param {string} listId controlled list id
 * @returns {Promise} axios request promise
 */
export function deleteControlledListTerm(listId, assetId, options) {
  return axios.delete(`/projects/${listId}/assets/${assetId}`, options)
}

/**
 * @description get info about where a controlled list term is used
 * @param {string} assetId asset id
 * @returns {Promise} axios request promise
 */
export function queryPropagationDetails(assetId) {
  return axios.get(`/mfcl/${assetId}/propagation-details`)
}

/**
 * @description fetch vocab list usage
 * @returns {Promise} axios request promise
 */
export function getVocabUsage(listId) {
  return axios.get(`/admin/vocab-list-usage/${listId}`)
}

/**
 * @description fetch vocab list usage details
 * @returns {Promise} axios request promise
 */
export function getVocabUsageDetails(listId) {
  return axios.get(`/admin/vocab-list-usage-details/${listId}`)
}
