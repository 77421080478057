import axios from 'axios'

const CancelToken = axios.CancelToken

/**
 * @description get a list of saved filter assets, optionally filtered by params
 * @param {string} filterId saved filter id
 * @param {object} options axios get options
 * @param {object} fetchProjectSource axios cancel token source
 * @returns {Promise} axios request promise
 */
export function querySavedFilterAssets(filterId, options, fetchProjectSource) {
  if (fetchProjectSource && fetchProjectSource.cancel) {
    fetchProjectSource.cancel()
  }
  Object.assign(fetchProjectSource, CancelToken.source())

  const { params } = options
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/saved-searches/${filterId}/assets?${queryParams}`, {
    cancelToken: fetchProjectSource.token
  })
}
