import axios from 'axios'
import { request } from '@/common/async.js'

const CancelToken = axios.CancelToken

/**
 * @description fetch a list of items in a project
 * @param {string} id project id
 * @param {object} options axios get request options
 *   options = { params: {
 *     with_meta: Boolean // send column information or not
 *    start: Number // start index for assets returned
 *    limit: Number // quantity of assets returned
 *    sort: String // asset property to sort by
 *    dir: String // sort direction 'ASC' or 'DESC'
 *    filter: Array // list of filter objects to filter by column values
 *    query: String // keyword search
 *  }},
 * @returns {Promise} axios request promise
 */
export async function queryProjectAssets(id, options, fetchProjectSource = {}) {
  fetchProjectSource?.cancel?.()
  Object.assign(fetchProjectSource, CancelToken.source())

  const queryParams = new URLSearchParams(options.params).toString()

  return axios.get(`/projects/${id}/assets?${queryParams}`, {
    cancelToken: fetchProjectSource.token
  })
}

/**
 * @description create a given number of empty assets for a project
 * @param {string} id project id
 * @param {number} quantity number of files to create
 *   options = {
 *     quantity: Number // number of files to create
 *   }
 * @returns {Promise} axios request promise
 */
export function saveProjectAssets(id, quantity) {
  const formData = new FormData()
  formData.append('number', quantity)

  return request({ url: `/_bulk/projects/${id}/create/assets`, method: 'POST', data: formData })
}

/**
 * @description delete a list of assets for a project
 * @param {string} id project id
 * @param {array} assetIds list of asset ids to delete
 * @returns {Promise} axios request promise
 */
export function deleteProjectAssets(options) {
  const { projectId, assetIds, selectAll, paramId, assetType, params } = options
  const formData = new FormData()
  if (selectAll) {
    formData.append('all', true)
    formData.append('filter', params.filter)
    if (params.query) {
      formData.append('query', params.query)
    }
  } else {
    assetIds.forEach(assetId => {
      formData.append('asset_ids', assetId)
    })
  }

  const id = selectAll ? paramId : projectId
  const type = selectAll ? assetType : 'projects'
  let url = `/_bulk/${type}/${id}/delete/assets`

  return request({ url, method: 'POST', data: formData })
}

/**
 * @description update a list of assets for a project
 * @param {string} id project id
 * @param {array} assetIds list of asset ids to update
 * @param {object} fields field names with values that were changed
 * @returns {Promise} axios request promise
 */
export function updateProjectAssets(assetType, paramId, filter, fields, query, selectAll) {
  const formData = new FormData()

  formData.append('update', JSON.stringify(fields))
  formData.append('filter', JSON.stringify(filter))

  if (selectAll) {
    formData.append('all', true)
  }
  if (query) {
    formData.append('query', query)
  }

  return request({ url: `/_bulk/${assetType}/${paramId}/update/assets`, method: 'POST', data: formData })
}

/**
 * @description delete a single asset group for a project
 * @param {string} projectId project id
 * @param {string} setId set id
 * @returns {Promise} axios request promise
 */
export function deleteProjectAssetGroup(projectId, setId) {
  return axios.delete(`/projects/${projectId}/asset-groups/${setId}`)
}

/**
 * @description remove list of assets from project asset group
 * @param {string} setId set id
 * @param {array} assetIds list of asset ids to remove
 * @returns {Promise} axios request promise
 */
export function removeFromProjectAssetGroup(setId, assetIds) {
  const assetData = new FormData()
  assetIds.forEach(id => {
    assetData.append('asset_ids', id)
  })
  return request({ url: `/_bulk/asset-groups/${setId}/remove/assets`, method: 'POST', data: assetData })
}

/**
 * @description create assets for a project from a given excel file
 * @param {string} id project id
 * @param {blob} file excel file containing asset data
 * @returns {Promise} axios request promise
 */
export function saveProjectAssetsFile(id, file) {
  const formData = new FormData()
  formData.append('data_file', file)

  return request({
    url: `/projects/${id}/assets.xls`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Disposition': 'form-data',
      'Content-Type': 'application/octet-stream'
    }
  })
}

/**
 * @description fetch a list of terms in a list
 * @param {string} id project id
 * @param {object} options axios get request options
 *   options = { params: {
      with_meta: Boolean // send column information or not
      start: Number // start index for assets returned
      limit: Number // quantity of assets returned
      sort: String // asset property to sort by
      dir: String // sort direction 'ASC' or 'DESC'
      filter: Array // list of filter objects to filter by column values
      query: String // keyword search
    }},
   @returns {Promise} axios request promise
 */
export async function queryListTerms(id, options, fetchProjectSource) {
  if (fetchProjectSource && fetchProjectSource.cancel) {
    fetchProjectSource.cancel()
  }
  Object.assign(fetchProjectSource, CancelToken.source())
  const queryParams = new URLSearchParams(options.params).toString()

  return axios.get(`/mfcl/${id}/terms?${queryParams}`, {
    cancelToken: fetchProjectSource.token
  })
}

/**
 * @description create terms for a controlled lsit from a given excel file
 * @param {string} id project id
 * @param {blob} file excel file containing asset data
 * @returns {Promise} axios request promise
 */
export function saveControlledListTermsFile(id, file) {
  const formData = new FormData()
  formData.append('data_file', file)

  return request({
    url: `/mfcl/${id}/terms.xls`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Disposition': 'form-data',
      'Content-Type': 'application/octet-stream'
    }
  })
}

/**
 * @description get a list of media sources for a given project
 * @param {string} id project id
 * @returns {Promise} axios request promise
 */
export function getProjectMediaSources(id) {
  return axios.get(`/projects/${id}/media-sources`)
}

/**
 * @description drs linking for a given projects assets
 * @param {string} id project id
 * @param {array} assetIds list of asset ids to link for DRS
 * @returns {Promise} axios request promise
 */
export function saveAutoLinkExternalMedia(id, assetIds) {
  const formData = new FormData()
  formData.set('asset_ids', JSON.stringify(assetIds))

  return axios.post(`/projects/${id}/auto-link-external-media`, formData)
}

/**
 * @description get forms for a given project
 * @param {string} id project id
 * @returns {Promise} axios request promise
 */
export function getProjectForms(id) {
  return axios.get(`/projects/${id}/forms`)
}

/**
 * @description update the data of an asset for a given project
 * @param {string} projectId project id
 * @param {string} assetId  asset id
 * @param {object} assetData asset data
 * @returns {Promise} axios request promise
 */
export function updateProjectAsset(projectId, assetId, assetData) {
  const formData = new FormData()
  formData.append('assets', JSON.stringify(assetData))

  return axios.put(`/projects/${projectId}/assets/${assetId}`, formData)
}

/**
 * @description add an asset to a given project
 * @param {string} projectId project id
 * @param {object} assetData asset data
 * @returns {Promise} axios request promise
 */
export function addProjectAsset(projectId, assetData) {
  const formData = new FormData()
  formData.append('assets', JSON.stringify(assetData))

  return axios.post(`/projects/${projectId}/assets`, formData)
}

/**
 * @description clear the media for a given asset
 * @param {string} projectId project id
 * @param {string} assetId asset id
 * @returns {Promise} axios request promise
 */
export function updateProjectAssetClearMedia(projectId, assetId) {
  const formData = new FormData()
  formData.append('clear_media', 'true')

  return axios.put(`/projects/${projectId}/assets/${assetId}`, formData)
}

/**
 * @description get a given project's asset groups (aka sets)
 * @param {string} projectId project id
 * @param {object} params query params
 * @param {object} fetchSetSource axios cancel token source
 * @returns {Promise} axios request promise
 */
export function queryProjectAssetGroups(projectId, params, fetchSetSource) {
  if (fetchSetSource.cancel) {
    fetchSetSource.cancel()
  }
  Object.assign(fetchSetSource, CancelToken.source())

  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/projects/${projectId}/asset-groups?${queryParams}`, {
    cancelToken: fetchSetSource.token
  })
}

/**
 * @description save a given project's asset groups (aka sets)
 * @param {string} projectId project id
 * @param {object} fields form fields
 * @returns {Promise} axios request promise
 */
export function saveProjectAssetGroups(projectId, fields) {
  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return axios.post(`/projects/${projectId}/asset-groups`, formData)
}

/**
 * @description update a given project's asset group (aka sets)
 * @param {string} projectId project id
 * @param {string} assetGroupId asset-group id
 * @param {object} fields form fields
 * @returns {Promise} axios request promise
 */
export function updateProjectAssetGroups(projectId, assetGroupId, fields) {
  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return axios.put(`/projects/${projectId}/asset-groups/${assetGroupId}`, formData)
}

/**
 * @description gets a give projects accounts
 * @param {string} projectId project id
 * @returns {Promise} axios request promise
 */
export function getProjectAccounts(projectId) {
  return axios.get(`/projects/${projectId}/accounts`)
}

/**
 * @description get a given project's saved filters
 * @param {string} projectId project id
 * @param {object} fetchFilterSource axios cancel token source
 * @returns {Promise} axios request promise
 */
export function queryProjectSavedFilters(projectId, fetchFilterSource) {
  if (fetchFilterSource.cancel) {
    fetchFilterSource.cancel()
  }
  Object.assign(fetchFilterSource, CancelToken.source())

  return axios.get(`/projects/${projectId}/saved-searches`, {
    cancelToken: fetchFilterSource.token
  })
}

/**
 * @description save a given project's saved filters
 * @param {string} projectId project id
 * @param {object} fields form fields
 * @param {object} searchParams applied filters {field: value}
 * @param {array} searchDesc applied filters array of objects with name, type, value props
 * @returns {Promise} axios request promise
 */
export function saveProjectSavedFilter(projectId, fields, searchParams, searchDesc) {
  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('search_parameters', JSON.stringify(searchParams))
  formData.append('search_description', JSON.stringify(searchDesc))

  return axios.post(`/projects/${projectId}/saved-searches`, formData)
}

/**
 * @description delete a single saved search for a project
 * @param {string} projectId project id
 * @param {string} filterId set id
 * @returns {Promise} axios request promise
 */
export function deleteProjectSavedSearch(projectId, filterId) {
  return axios.delete(`/projects/${projectId}/saved-searches/${filterId}`)
}

/**
 * @description update a given project's saved filter
 * @param {string} projectId project id
 * @param {string} filterId filter id
 * @param {object} fields form fields
 * @param {object} searchParams applied filters {field: value}
 * @param {array} searchDesc applied filters array of objects with name, type, value props
 * @returns {Promise} axios request promise
 */
export function updateProjectSavedFilter(projectId, filterId, fields, searchParams, searchDesc) {
  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('search_parameters', JSON.stringify(searchParams))
  formData.append('search_description', JSON.stringify(searchDesc))

  return axios.put(`/projects/${projectId}/saved-searches/${filterId}`, formData)
}

/**
 * @description find multiple value fields
 * @param {string} assetType is the type of the project tab selected (i.e. projects, asset-groups or saved-searches)
 * @param {string} paramId is the id of the assetType
 * @param {string} filter is the stringified filter array
 * @returns {Promise} axios request promise
 */
export function compareProjectAssets(assetType, paramId, filter, query) {
  const params = {}

  if (filter) {
    params.filter = filter
  }

  if (query) {
    params.query = query
  }

  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/${assetType}/${paramId}/assets-unique-fields?${queryParams}`)
}

/**
 * @description find multiple value fields
 * @param {string} projectId is the project id of the asset
 * @param {string} workId is the id for the work record to which the map should be applied
 * @param {string} behaviour is the link behaviour (either "link" or "overwrite')
 * @returns {Promise} axios request promise
 */
export function executeMap(projectId, workId, behaviour) {
  const body = new URLSearchParams()
  body.append('work_record_id', workId)
  body.append('behaviour', behaviour)
  return axios.post(`/project/${projectId}/executeMap`, body).then(response => response.data)
}
